import React from "react";
import Form from "./common/Form";
import { Container, Row, Col } from "react-bootstrap";
import Orders from "../components/Orders";

import Joi from "joi-browser";
import { getCustomerById, updateCustomer } from "../services/customerService";
import { getSubById, createSub, updateSub } from "../services/subService";
import { getProductsLatest } from "../services/productService";
import auth from "../services/authService";
import ArvatoRecurringInvoices from "./ArvatoRecurringInvoices";
import ArvatoRecurringSubscription from "./ArvatoRecurringSubscription";

import { sendInvitation } from "../services/trustpilotService";
import Dialog from "./common/Dialog";
import { toast } from "react-toastify";
import date from "date-and-time";

class EditSub extends Form {
  state = {
    data: {
      product: "",
    },
    customer: {},
    errors: {},
    sub: {},
    products: [],
    dialog: {},
    trustpilot: null,
  };

  schema = {
    product: Joi.string().required().label("Subscription"),
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      let { data, products, sub, customer } = { ...this.state };

      if (this.props.match.params.subid !== "new") {
        sub = await getSubById(this.props.match.params.subid);

        data.product = sub.product.name;
      }

      customer = await getCustomerById(this.props.match.params.customerid);

      let result = await getProductsLatest(customer.country);
      products.push("");

      for (const key in result) {
        products.push(result[key].name);
      }

      this.setState({ data, sub, products, customer, trustpilot: customer.trustpilotSent ? customer.trustpilotSent : null });
    }
  }

  doSubmit = async () => {
    let { data, errors } = this.state;
    const subId = this.props.match.params.subid;

    if (subId === "new") {
      try {
        let customer = await getCustomerById(this.props.match.params.customerid);
        const sub = await createSub(data, customer);
        customer.subs.push(sub);
        customer = await updateCustomer(customer);
        this.props.updateCustomerSubData(customer, sub, "add");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          errors.name = ex.response.data;
          this.setState({ errors });
        }
      }
    } else {
      try {
        delete data.price;
        data._id = subId;

        await updateSub(data);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          errors.name = ex.response.data;
          this.setState({ errors });
        }
      }
    }
    this.props.history.push("/customers/" + this.props.match.params.customerid);
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.push("/customers/" + this.props.match.params.customerid);
  };

  confirm = async (e) => {
    e.stopPropagation();

    let { dialog } = this.state;
    dialog.open = true;
    dialog.title = "Send Trustpilot invitation?";
    dialog.text = "Sure you want to send a trustpilot invitation to this customer?";
    dialog.doYes = this.sendTrustpilot;

    this.setState({ dialog });
  };

  sendTrustpilot = async () => {
    try {
      const response = await sendInvitation({ custId: this.props.match.params.customerid, subId: this.props.match.params.subid });
      if (response.err) toast.error(response.message);
      else {
        let trustpilot = null;
        const customer = await getCustomerById(this.props.match.params.customerid);
        if (customer.trustpilotSent) trustpilot = customer.trustpilotSent;
        this.setState({ dialog: { open: false }, trustpilot });
        toast.success(response.message);
      }
    } catch (err) {
      this.setState({ dialog: { open: false } });
      toast.error("Something failed when trying to send invite.");
    }
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  render() {
    let factoring = this.state.sub.factoring;
    if (factoring === "arvato") {
      factoring = "riverty";
    }
    return (
      <>
        <Dialog
          open={this.state.dialog.open}
          doNo={this.closeModal}
          doYes={this.state.dialog.doYes}
          title={this.state.dialog.title}
          text={this.state.dialog.text}
          loading={this.state.dialog.loading}
          yes="Yes"
          no="No"
        />
        <Container>
          <Row>
            <Col className="mb-3 mt-3">{this.props.match.params.subid === "new" ? <h1>Adding new subscription</h1> : <h1>Viewing subscription</h1>}</Col>
          </Row>
          <Row>
            <Col>
              <h3>CustomerNo: {this.state.customer.id}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                Created:{" "}
                {this.state.sub.created &&
                  Intl.DateTimeFormat("no-NO", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }).format(new Date(this.state.sub.created))}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                Updated:{" "}
                {this.state.sub.updated &&
                  Intl.DateTimeFormat("no-NO", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }).format(new Date(this.state.sub.updated))}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Product: {this.state.sub.product && this.state.sub.product.name}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Active: {this.state.sub.active ? this.state.sub.active.toString() : "false"}</h3>
            </Col>
          </Row>
          <Row>
            <Col className={this.state.sub.factoring === "arvato-recurring" ? "arvato-recurring" : ""}>
              <h3>Factoring: {factoring ?? "Unknown"}</h3>
            </Col>
          </Row>
          {this.state.sub.source && (
            <Row>
              <Col>
                <h3>
                  Source: {this.state.sub.source.type} {this.state.sub.source.affId && ` (affId: ${this.state.sub.source.affId})`}
                </h3>
              </Col>
            </Row>
          )}
          {this.state.sub.unsubReason && (
            <Row>
              <Col>
                <h3>Unsub reason: {this.state.sub.unsubReason.reason}</h3>
              </Col>
            </Row>
          )}
          {this.state.sub.events && this.state.sub.events.length > 0 && <h3>Events:</h3>}
          {this.state.sub.events &&
            this.state.sub.events.map((event, index) => {
              return (
                <Row>
                  <Col>{event.event}</Col>
                  <Col>
                    {Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }).format(new Date(event.date))}
                  </Col>
                  <Col>{event.channel}</Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              );
            })}
          {/* <Row>
            <Col>
              {!this.state.trustpilot ? (
                <button className="btn btn-primary ml-3" onClick={(e) => this.confirm(e)}>
                  Send Trustpilot Product Review
                </button>
              ) : (
                <h3>Trustpilot sent: {this.state.trustpilot && date.format(new Date(this.state.trustpilot), "DD.MM.YYYY HH:mm:ss")}</h3>
              )}
            </Col>
          </Row> */}
          <Row>
            <Col className="mt-3">
              <form onSubmit={this.handleSubmit}>
                {" "}
                <div className="mb-3">
                  {this.props.match.params.subid === "new" && this.renderButton("Save")}
                  <button className="btn btn-secondary ml-3" onClick={this.handleBack}>
                    Back
                  </button>
                </div>
                {this.props.match.params.subid === "new" && this.renderDropdown("product", "Subscription", this.state.products)}
              </form>
            </Col>
          </Row>
          <Row>
            <Col>{this.props.match.params.subid !== "new" && <Orders orders={this.state.sub.orders} {...this.props} sub={this.state.sub} />}</Col>
          </Row>
          <Row>
            <Col>{this.state.sub.factoring === "arvato-recurring" && <ArvatoRecurringSubscription sub={this.state.sub} {...this.props} />}</Col>
          </Row>
          <Row>
            <Col>{this.state.sub.factoring === "arvato-recurring" && <ArvatoRecurringInvoices sub={this.state.sub} {...this.props} />}</Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditSub;
