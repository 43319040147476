import React, { Component, useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Chart from "./common/Chart";
import ContainerDimensions from "react-container-dimensions";
import auth from "../services/authService";
import { getFlag } from "../utils/countries";

const countries = ["no", "se", "dk", "fi", "nl", "de", "at", "be", "pl", "cz", "ie", "pt", "fr", "es"];

const DelayedTD = ({ value, className }) => {
  const [renderedValue, setRenderedValue] = useState(value);
  useEffect(() => {
    if (!renderedValue) {
      setRenderedValue(value);
    } else {
      setTimeout(() => {
        setRenderedValue(value);
      }, 500);
    }
  }, [value]);
  return <td className={className}>{renderedValue}</td>;
};

const AnimatedTD = ({ value, className }) => {
  const [classNames, setClassnames] = useState(className);
  const prevCountRef = useRef();
  const animatedClassNames = `${className} dashboard-data-column-animate`;
  if (!prevCountRef.current) prevCountRef.current = value;
  useEffect(() => {
    prevCountRef.current = value;
  }, [value]);

  if (prevCountRef.current !== value && classNames !== animatedClassNames) {
    setClassnames(animatedClassNames);
    setTimeout(() => {
      setClassnames(className);
    }, 500);
  }

  const prevCount = prevCountRef.current;

  return <DelayedTD value={value} className={classNames} />;
};

const getDataTable = (newProducts, paramName) => {
  const sums = countries.map((country) => newProducts.reduce((acc, value) => acc + value[country][paramName], 0));
  const sumAll = sums.reduce((a, b) => a + b, 0);
  return (
    <table className="dashboard-data-table">
      <thead>
        <th></th>
        {countries.map((country) => (
          <th key={country} style={{ width: 30 }}>
            {getFlag(country)}
          </th>
        ))}
        <th style={{ width: 30 }}>all</th>
      </thead>
      <tbody>
        {newProducts.map((value, index) => {
          if (value.name && value.name !== "SeaTrue" && value.name !== "Q22")
            return (
              <tr>
                <td className="dashboard-data-column-name">{value.name}</td>
                {countries.map((country) => (
                  <AnimatedTD key={country} className="dashboard-data-column-country" value={value[country][paramName]} />
                ))}
                <DelayedTD className="dashboard-data-column-country" value={value[paramName]} />
              </tr>
            );
        })}
        <tr>
          <td className="dashboard-data-column-name">All</td>
          {sums.map((val) => (
            <DelayedTD className="dashboard-data-column-country" value={val} />
          ))}
          <DelayedTD className="dashboard-data-column-country" value={sumAll} />
        </tr>
      </tbody>
    </table>
  );
};

class Home extends Component {
  state = {};

  async componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { ordersToday, ordersYesterday, ordersMonth, ordersTotal, activeSubs, activeSubsUnsubed, products } = this.props.home.orders;

    products.sort((a, b) => a.name.localeCompare(b.name));
    const unsubs = this.props.home.unsubs;
    const churn = this.props.home.churn;

    let newProducts = [];

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const country = product.country ?? "no";
      if (country === "ch") continue;
      let productName = "";

      if (product.name.includes("Melatonin Vital")) productName = "Melatonin Vital";
      else if (product.name.includes("Collagen Vital")) productName = "Collagen Vital";
      else if (product.name.includes("Hair And Skin")) productName = "Hair And Skin";
      else if (product.name.includes("Mory")) productName = "Mory";
      else if (product.name.includes("Energy B12D3")) productName = "Energy B12D3";
      else if (product.name.includes("Q22")) productName = "Q22";
      else if (product.name.includes("Craveless")) productName = "Craveless";
      else if (product.name.includes("T-Complex")) productName = "T-Complex";
      else if (product.name.includes("ProbiForte")) productName = "BioBelly";
      else if (product.name.includes("BioBelly")) productName = "BioBelly";
      else if (product.name.includes("SeaTrue")) productName = "SeaTrue";
      else if (product.name.includes("FLEXYPRO")) productName = "FLEXYPRO";
      else if (product.name.includes("SLEEPMORE")) productName = "SLEEPMORE";
      else if (product.name.includes("MemRY")) productName = "MemRY";
      else if (product.name.includes("Collagen Multi")) productName = "Collagen Multi";
      else if (product.name.includes("Mello17")) productName = "Mello17";
      else if (product.name.includes("Retine")) productName = "Retine";
      else if (product.name.includes("MEDINIGHT")) productName = "MEDINIGHT";
      else if (product.name.includes("hues Glow")) productName = "hues Glow";
      else if (product.name.includes("Hues Skin")) productName = "Hues Skin";
      else if (product.name.includes("Estrodoxin")) productName = "Estrodoxin";
      else if (product.name.includes("Enzytin")) productName = "Enzytin";
      else if (product.name.includes("Summit Magnesium")) productName = "Summit Magnesium";

      let mvIndex = newProducts.findIndex((x) => x.name === productName);
      if (mvIndex === -1) {
        newProducts.push({
          name: productName,
          ordersToday: 0,
          ordersYesterday: 0,
          ordersMonth: 0,
          ordersTotal: 0,
          activeSubs: 0,
          activeSubsUnsubed: 0,
        });
        mvIndex = newProducts.length - 1;
        countries.map(
          (country) =>
            (newProducts[mvIndex][country] = {
              ordersToday: 0,
              ordersYesterday: 0,
              ordersMonth: 0,
              ordersTotal: 0,
              activeSubs: 0,
              activeSubsUnsubed: 0,
            })
        );
      }

      newProducts[mvIndex][country].ordersToday += product.ordersToday;
      newProducts[mvIndex][country].ordersYesterday += product.ordersYesterday;
      newProducts[mvIndex][country].ordersMonth += product.ordersMonth;
      newProducts[mvIndex][country].ordersTotal += product.ordersTotal;
      newProducts[mvIndex][country].activeSubs += product.activeSubs;
      newProducts[mvIndex][country].activeSubsUnsubed += product.activeSubsUnsubed;
      newProducts[mvIndex].ordersToday += product.ordersToday;
      newProducts[mvIndex].ordersYesterday += product.ordersYesterday;
      newProducts[mvIndex].ordersMonth += product.ordersMonth;
      newProducts[mvIndex].ordersTotal += product.ordersTotal;
      newProducts[mvIndex].activeSubs += product.activeSubs;
      newProducts[mvIndex].activeSubsUnsubed += product.activeSubsUnsubed;
    }

    return (
      <Container fluid>
        <div className={this.state.user && this.state.user.role === "home" ? "larger-text" : ""}>
          <Row style={{ textAlign: "center" }}>
            <Col className="mb-3 mt-3 col-12">
              <h1>
                Dashboard
                <button className="refresh" onClick={this.props.refreshHome}>
                  <i className={this.props.animation ? "fas fa-sync-alt fa-2x fa-spin" : "fas fa-sync-alt fa-2x theme-color"}></i>
                </button>
              </h1>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col className="col-12 col-md-4 mb-5">
              <h3>Subs today</h3>
              <h1>{ordersToday}</h1>
              {getDataTable(newProducts, "ordersToday")}
            </Col>
            <Col className="col-12 col-md-4 mb-5">
              <h3>Subs yesterday</h3>
              <h1>{ordersYesterday}</h1>
              {getDataTable(newProducts, "ordersYesterday")}
            </Col>
            <Col className="col-12 col-md-4 mb-5">
              <h3>Subs this month</h3>
              <h1>{ordersMonth}</h1>
              {getDataTable(newProducts, "ordersMonth")}
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col className={this.state.user && this.state.user.role === "home" ? "col-12 col-md-6 mb-5" : "col-12 col-md-4 mb-5"}>
              <h3>Subs total</h3>
              <h1>{ordersTotal}</h1>
              {getDataTable(newProducts, "ordersTotal")}
            </Col>
            <Col className={this.state.user && this.state.user.role === "home" ? "col-12 col-md-6 mb-5" : "col-12 col-md-4 mb-5"}>
              <h3>Active subs total</h3>
              <h1>
                {activeSubs} (-{activeSubsUnsubed})
              </h1>
              {getDataTable(newProducts, "activeSubs")}
            </Col>
            {this.state.user && this.state.user.role === "home" ? (
              ""
            ) : (
              <Col className="col-12 col-md-4 mb-5">
                <h3>Un-subs</h3>
                <h4>Today: {unsubs.today}</h4>
                <h4>Yesterday: {unsubs.yesterday}</h4>
                <h5>This month: {unsubs.month}</h5>
                <h6>Total: {unsubs.total}</h6>
              </Col>
            )}
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col className="col-12 mb-5">
              <h3>Trailing 30 days</h3>
              <ContainerDimensions>
                {({ width, height }) => (
                  <Chart
                    data={this.props.home.trailData}
                    avg={this.props.home.thisMonthAvg}
                    avgLabel={this.props.home.thisMonthLabels}
                    width={width}
                    height={300}
                    type="line"
                    ytitle="Time"
                    xtitle="Orders"
                  />
                )}
              </ContainerDimensions>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col className="col-12 mb-5">
              <h3>Lifetime orders</h3>
              <ContainerDimensions>
                {({ width, height }) => <Chart data={this.props.home.subData} width={width} height={300} type="line" ytitle="Time" xtitle="Orders" />}
              </ContainerDimensions>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col className="col-12 mb-5">
              <h3>Subscriber trend</h3>
              <ContainerDimensions>
                {({ width, height }) => <Chart data={this.props.home.accuSubs} width={width} height={300} type="line" ytitle="Time" xtitle="Subscrubers" />}
              </ContainerDimensions>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Home;
